define("ember-concurrency/-private/external/task-instance/base", ["exports", "ember-concurrency/-private/external/task-instance/initial-state", "ember-concurrency/-private/external/yieldables", "ember-concurrency/-private/external/task-instance/cancelation"], function (_exports, _initialState, _yieldables, _cancelation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BaseTaskInstance = void 0;
  const EXPLICIT_CANCEL_REASON = '.cancel() was explicitly called';
  class BaseTaskInstance {
    constructor(_ref) {
      let {
        task,
        args,
        executor,
        performType,
        hasEnabledEvents
      } = _ref;
      this.task = task;
      this.args = args;
      this.performType = performType;
      this.executor = executor;
      this.executor.taskInstance = this;
      this.hasEnabledEvents = hasEnabledEvents;
    }
    setState() {}
    onStarted() {}
    onSuccess() {}
    onError() {}
    onCancel() {}
    formatCancelReason() {}
    selfCancelLoopWarning() {}
    onFinalize(callback) {
      this.executor.onFinalize(callback);
    }
    proceed(index, yieldResumeType, value) {
      this.executor.proceedChecked(index, yieldResumeType, value);
    }
    [_yieldables.yieldableSymbol](parentTaskInstance, resumeIndex) {
      return this.executor.onYielded(parentTaskInstance, resumeIndex);
    }
    cancel() {
      let cancelReason = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : EXPLICIT_CANCEL_REASON;
      this.executor.cancel(new _cancelation.CancelRequest(_cancelation.CANCEL_KIND_EXPLICIT, cancelReason));
    }
    then() {
      return this.executor.promise().then(...arguments);
    }
    catch() {
      return this.executor.promise().catch(...arguments);
    }
    finally() {
      return this.executor.promise().finally(...arguments);
    }
    toString() {
      return `${this.task} TaskInstance`;
    }
    start() {
      this.executor.start();
      return this;
    }
  }
  _exports.BaseTaskInstance = BaseTaskInstance;
  Object.assign(BaseTaskInstance.prototype, _initialState.INITIAL_STATE);
  Object.assign(BaseTaskInstance.prototype, {
    state: 'waiting',
    isDropped: false,
    isRunning: true
  });
});