define("@ember-data/serializer/transform", ["exports", "@ember/object", "@ember-data/serializer/string-22572f80"], function (_exports, _object, _string22572f) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BooleanTransform", {
    enumerable: true,
    get: function () {
      return _string22572f.B;
    }
  });
  Object.defineProperty(_exports, "DateTransform", {
    enumerable: true,
    get: function () {
      return _string22572f.D;
    }
  });
  Object.defineProperty(_exports, "NumberTransform", {
    enumerable: true,
    get: function () {
      return _string22572f.N;
    }
  });
  Object.defineProperty(_exports, "StringTransform", {
    enumerable: true,
    get: function () {
      return _string22572f.S;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _object.default;
    }
  });
});