define("@ember-data/model/-private", ["exports", "@ember-data/model/has-many-774c4c4b", "@ember-data/model/model-b638e17c"], function (_exports, _hasMany774c4c4b, _modelB638e17c) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Errors", {
    enumerable: true,
    get: function () {
      return _modelB638e17c.E;
    }
  });
  Object.defineProperty(_exports, "LEGACY_SUPPORT", {
    enumerable: true,
    get: function () {
      return _modelB638e17c.L;
    }
  });
  Object.defineProperty(_exports, "ManyArray", {
    enumerable: true,
    get: function () {
      return _modelB638e17c.R;
    }
  });
  Object.defineProperty(_exports, "Model", {
    enumerable: true,
    get: function () {
      return _modelB638e17c.M;
    }
  });
  Object.defineProperty(_exports, "PromiseBelongsTo", {
    enumerable: true,
    get: function () {
      return _modelB638e17c.P;
    }
  });
  Object.defineProperty(_exports, "PromiseManyArray", {
    enumerable: true,
    get: function () {
      return _modelB638e17c.a;
    }
  });
  Object.defineProperty(_exports, "attr", {
    enumerable: true,
    get: function () {
      return _hasMany774c4c4b.a;
    }
  });
  Object.defineProperty(_exports, "belongsTo", {
    enumerable: true,
    get: function () {
      return _hasMany774c4c4b.b;
    }
  });
  Object.defineProperty(_exports, "hasMany", {
    enumerable: true,
    get: function () {
      return _hasMany774c4c4b.h;
    }
  });
});