define("@ember-data/model/index", ["exports", "@ember-data/model/has-many-774c4c4b", "@ember-data/model/model-b638e17c", "@ember-data/model/hooks-6d5c2fc2"], function (_exports, _hasMany774c4c4b, _modelB638e17c, _hooks6d5c2fc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ModelSchemaProvider", {
    enumerable: true,
    get: function () {
      return _hooks6d5c2fc.M;
    }
  });
  Object.defineProperty(_exports, "attr", {
    enumerable: true,
    get: function () {
      return _hasMany774c4c4b.a;
    }
  });
  Object.defineProperty(_exports, "belongsTo", {
    enumerable: true,
    get: function () {
      return _hasMany774c4c4b.b;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modelB638e17c.M;
    }
  });
  Object.defineProperty(_exports, "hasMany", {
    enumerable: true,
    get: function () {
      return _hasMany774c4c4b.h;
    }
  });
  Object.defineProperty(_exports, "instantiateRecord", {
    enumerable: true,
    get: function () {
      return _hooks6d5c2fc.i;
    }
  });
  Object.defineProperty(_exports, "modelFor", {
    enumerable: true,
    get: function () {
      return _hooks6d5c2fc.m;
    }
  });
  Object.defineProperty(_exports, "teardownRecord", {
    enumerable: true,
    get: function () {
      return _hooks6d5c2fc.t;
    }
  });
});