define("@ember-data/serializer/utils-075c5b79", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.c = coerceId;
  function coerceId(id) {
    if (id === null || id === undefined || id === '') {
      return null;
    } else if (typeof id === 'string') {
      return id;
    } else if (typeof id === 'symbol') {
      return id.toString();
    } else {
      return String(id);
    }
  }
});