define("ember-composable-helpers/helpers/dec", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dec = dec;
  _exports.default = void 0;
  function dec(_ref) {
    let [step, val] = _ref;
    if ((0, _utils.isEmpty)(val)) {
      val = step;
      step = undefined;
    }
    val = Number(val);
    if (isNaN(val)) {
      return;
    }
    if (step === undefined) {
      step = 1;
    }
    return val - step;
  }
  var _default = _exports.default = (0, _helper.helper)(dec);
});