define("@ember-data/serializer/-private", ["exports", "@ember-data/serializer/embedded-records-mixin-d75385ff", "@ember/object", "@ember-data/serializer/string-22572f80", "@ember-data/serializer/utils-075c5b79"], function (_exports, _embeddedRecordsMixinD75385ff, _object, _string22572f, _utils075c5b) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BooleanTransform", {
    enumerable: true,
    get: function () {
      return _string22572f.B;
    }
  });
  Object.defineProperty(_exports, "DateTransform", {
    enumerable: true,
    get: function () {
      return _string22572f.D;
    }
  });
  Object.defineProperty(_exports, "EmbeddedRecordsMixin", {
    enumerable: true,
    get: function () {
      return _embeddedRecordsMixinD75385ff.e;
    }
  });
  Object.defineProperty(_exports, "NumberTransform", {
    enumerable: true,
    get: function () {
      return _string22572f.N;
    }
  });
  Object.defineProperty(_exports, "StringTransform", {
    enumerable: true,
    get: function () {
      return _string22572f.S;
    }
  });
  Object.defineProperty(_exports, "Transform", {
    enumerable: true,
    get: function () {
      return _object.default;
    }
  });
  Object.defineProperty(_exports, "coerceId", {
    enumerable: true,
    get: function () {
      return _utils075c5b.c;
    }
  });
});