define("@ember-data/model/hooks", ["exports", "@ember-data/model/hooks-6d5c2fc2"], function (_exports, _hooks6d5c2fc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "buildSchema", {
    enumerable: true,
    get: function () {
      return _hooks6d5c2fc.b;
    }
  });
  Object.defineProperty(_exports, "instantiateRecord", {
    enumerable: true,
    get: function () {
      return _hooks6d5c2fc.i;
    }
  });
  Object.defineProperty(_exports, "modelFor", {
    enumerable: true,
    get: function () {
      return _hooks6d5c2fc.m;
    }
  });
  Object.defineProperty(_exports, "teardownRecord", {
    enumerable: true,
    get: function () {
      return _hooks6d5c2fc.t;
    }
  });
});