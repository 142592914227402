define("@ember-data/request/fetch", ["exports", "@ember-data/request/context-49bbb842"], function (_exports, _context49bbb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A basic Fetch Handler which converts a request into a
   * `fetch` call presuming the response to be `json`.
   *
   * ```ts
   * import Fetch from '@ember-data/request/fetch';
   *
   * manager.use([Fetch]);
   * ```
   *
   * @module @ember-data/request/fetch
   * @main @ember-data/request/fetch
   */

  const _fetch = typeof fetch !== 'undefined' ? fetch : typeof FastBoot !== 'undefined' ? FastBoot.require('node-fetch') : () => {
    throw new Error('No Fetch Implementation Found');
  };

  // clones a response in a way that should still
  // allow it to stream
  function cloneResponse(response, overrides) {
    const props = (0, _context49bbb.c)(response);
    return new Response(response.body, Object.assign(props, overrides));
  }
  const MUTATION_OPS = new Set(['updateRecord', 'createRecord', 'deleteRecord']);

  /**
   * A basic handler which converts a request into a
   * `fetch` call presuming the response to be `json`.
   *
   * ```ts
   * import Fetch from '@ember-data/request/fetch';
   *
   * manager.use([Fetch]);
   * ```
   *
   * @class Fetch
   * @public
   */
  const Fetch = _exports.default = {
    async request(context) {
      let response = await _fetch(context.request.url, context.request);
      const isError = !response.ok || response.status >= 400;
      const op = context.request.op;
      const isMutationOp = Boolean(op && MUTATION_OPS.has(op));
      if (!isError && !isMutationOp && response.status !== 204 && !response.headers.has('date')) {
        const headers = new Headers(response.headers);
        headers.set('date', new Date().toUTCString());
        response = cloneResponse(response, {
          headers
        });
      }
      context.setResponse(response);

      // if we are an error, we will want to throw
      if (isError) {
        const text = await response.text();
        let errorPayload;
        try {
          errorPayload = JSON.parse(text);
        } catch {
          // void;
        }
        const error = new Error(`[${response.status}] ${response.statusText} - ${response.url}`);
        error.content = errorPayload;
        throw error;
      } else {
        return response.status === 204 ? null : response.json();
      }
    }
  };
});