define("ember-cp-validations/utils/deep-set", ["exports", "ember-cp-validations/utils/utils", "@ember/utils", "@ember/object"], function (_exports, _utils, _utils2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = deepSet;
  /**
   * Assigns a value to an object via the given path while creating new objects if
   * the pathing requires it. If the given path is `foo.bar`, it will create a new object (obj.foo)
   * and assign value to obj.foo.bar. If the given object is an EmberObject, it will create new EmberObjects.
   */

  function deepSet(obj, path, value) {
    let useEmberObject = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    let delimiter = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '.';
    let keyPath = path.split(delimiter);
    let lastKeyIndex = keyPath.length - 1;
    let currObj = obj;

    // Iterate over each key in the path (minus the last one which is the property to be assigned)
    for (let i = 0; i < lastKeyIndex; ++i) {
      let key = keyPath[i];

      // Create a new object if it doesnt exist
      if ((0, _utils2.isNone)((0, _object.get)(currObj, key))) {
        (0, _object.set)(currObj, key, useEmberObject ? _object.default.create() : {});
      }
      currObj = (0, _object.get)(currObj, key);
    }
    if ((0, _utils.isDescriptor)(value)) {
      (0, _object.defineProperty)(currObj, keyPath[lastKeyIndex], value);
    } else {
      (0, _object.set)(currObj, keyPath[lastKeyIndex], value);
    }
  }
});