define("@ember-data/store/index", ["exports", "@ember-data/store/store-service-aee9dd76"], function (_exports, _storeServiceAee9dd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "CacheHandler", {
    enumerable: true,
    get: function () {
      return _storeServiceAee9dd.C;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _storeServiceAee9dd.S;
    }
  });
  Object.defineProperty(_exports, "recordIdentifierFor", {
    enumerable: true,
    get: function () {
      return _storeServiceAee9dd.r;
    }
  });
  Object.defineProperty(_exports, "setIdentifierForgetMethod", {
    enumerable: true,
    get: function () {
      return _storeServiceAee9dd.c;
    }
  });
  Object.defineProperty(_exports, "setIdentifierGenerationMethod", {
    enumerable: true,
    get: function () {
      return _storeServiceAee9dd.a;
    }
  });
  Object.defineProperty(_exports, "setIdentifierResetMethod", {
    enumerable: true,
    get: function () {
      return _storeServiceAee9dd.d;
    }
  });
  Object.defineProperty(_exports, "setIdentifierUpdateMethod", {
    enumerable: true,
    get: function () {
      return _storeServiceAee9dd.b;
    }
  });
  Object.defineProperty(_exports, "storeFor", {
    enumerable: true,
    get: function () {
      return _storeServiceAee9dd.s;
    }
  });
});