define("ember-cp-validations/-private/ember-internals", ["exports", "@ember/-internals/metal"], function (_exports, _metal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDependentKeys = getDependentKeys;
  _exports.isDescriptor = isDescriptor;
  function getDependentKeys(descriptorOrDecorator) {
    if (_metal.descriptorForDecorator) {
      let descriptor = (0, _metal.descriptorForDecorator)(descriptorOrDecorator);
      return descriptor._dependentKeys || [descriptor.altKey];
    } else {
      return descriptorOrDecorator._dependentKeys;
    }
  }
  function isDescriptor(o) {
    if (_metal.isClassicDecorator) {
      return (0, _metal.isClassicDecorator)(o);
    } else {
      return o && (typeof o === 'object' || typeof o === 'function') && o.isDescriptor;
    }
  }
});